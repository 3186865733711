import styled from 'styled-components';
import { breakpoints } from 'variables';

export const ListContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   width: 100%;
   margin-top: 30px;

   ${breakpoints.md`
      margin-top: 100px;
      flex-direction: row;
   `};
`;

export const ImageContainer = styled.div`
   display: flex;
   justify-content: center;
   height: 210px;

   ${breakpoints.lg`
      justify-content: flex-start;
   `};
`;

export const ListItem = styled.div`
   display: flex;
   flex-basis: 100%;
   flex-direction: column;
   justify-content: space-between;
   margin: 20px 0;

   img {
      margin-bottom: 35px;
   }

   ${breakpoints.md`
      margin: 0;
      padding: 0 15px;

      &:first-of-type {
         padding-left: 0;
      }

      &:last-of-type {
         padding-right: 0;
      }
   `};
`;

export const ListDescription = styled.section`
   font-weight: 300;
   display: flex;
   flex-direction: column;
   flex: 1 1 100%;

   h4 {
      flex-grow: 1;
      margin-bottom: 20px;
      font-weight: 300;
      line-height: 32px;

      strong {
         font-weight: 700;
      }
   }
`;

export const ListText = styled.p`
   margin: 0;
   flex-grow: 1;

   ${breakpoints.lg`
      padding-right: 40px;
   `}
`;
