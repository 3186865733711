import React from 'react';

import SectionHeader from 'components/shared/SectionHeader';
import ListWithIcons from 'components/pages/benefits/ListWithIcons';

import illustration1 from 'assets/icons/Ilustracja2.svg';
import illustration2 from 'assets/icons/Ilustracja3.svg';
import illustration3 from 'assets/icons/Ilustracja4.svg';

const PossibilitiesSection = () => {
   const sectionText = [<strong>Możliwości</strong>, ' systemu Proces Control'];

   const listItems = [
      {
         header: [<strong>Łatwość</strong>, ' we wdrożeniu i dopasowaniu do potrzeb'],
         description:
            'Sam tworzysz szablony każdej karty pacjenta i każdej specjalizacji obsługiwanej w przychodni.',
         image: illustration1,
      },
      {
         header: [<strong>Mobilność</strong>, ' systemu daje więcej możliwości'],
         description:
            'Zalogujesz się z każdego sprzętu z dostępem do internetu.',
         image: illustration2,
      },
      {
         header: [<strong>Nie potrzebujesz</strong>, ' wsparcia IT z naszej strony'],
         description:
            'Rola Admina w systemie daje Ci władzę absolutną.',
         image: illustration3,
      },
   ];

   return (
      <>
         <SectionHeader sectionText={sectionText} />

         <ListWithIcons listItems={listItems} />
      </>
   );
};

export default PossibilitiesSection;
