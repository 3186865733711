import React from 'react';

import cost from 'assets/icons/benefits/sample2.svg';
import forms from 'assets/icons/benefits/sample2.svg';
import plan from 'assets/icons/benefits/sample2.svg';
import image from 'assets/images/benefits/photo13.png';
import imageX2 from 'assets/images/benefits/photo13@2x.png';
import ListWithImage from 'components/pages/benefits/ListWithImage';
import { colors } from 'styles/variables';
import Container from 'components/shared/Container';

const CustomizationSection = () => {
   const listHeader = [
      <strong>Dostosuj nasz system</strong>,
      ' do swoich indywidualnych potrzeb',
   ];

   const listItems = [
      {
         headerText: [<strong>Twórz</strong>, ' własne formularze pacjenta'],
         description:
            'Nie zmieniaj swoich przyzwyczajeń - przenieś swoje papierowe formularze na elektroniczne.',
         icon: forms,
      },
      {
         headerText: [<strong>Wybieraj</strong>, ' informacje, które chcesz zbierać'],
         description:
            `Wprowadź swoje: dane placówki (np. gabinety, lekarze, specjalizacje, godziny otwarcia, itp.) i dane pacjenta, wprowadź dane pacjenta, których potrzebujesz, ściągaj dane, które są dostępne`,
         icon: cost,
      },
   ];

   return (
      <Container>
         <ListWithImage
            headerText={listHeader}
            listItems={listItems}
            image={image}
            imageX2={imageX2}
            imgPosition="right"
            bgColor={colors.lightCyanMap.get(300)}
         />
      </Container>
   );
};

export default CustomizationSection;
