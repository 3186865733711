import React from 'react';

import SectionHeader from 'components/shared/SectionHeader';
import VideoPlayer from 'components/pages/benefits/VideoPlayer';

const FormsPreviewSection = () => {
   const sectionText = [
      <strong>Zamieniaj papierowe formularze</strong>,
      ' na cyfrowe bez wsparcia IT',
   ];

   return (
      <>
         <SectionHeader sectionText={sectionText} />

         <VideoPlayer />
      </>
   );
}

export default FormsPreviewSection;
