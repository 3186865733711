import React from 'react';

import Container from 'components/shared/Container';

import { VideoContainer } from './VideoPlayer.styled';
import image from 'assets/images/implementation/Mockup.png';
import imageX2 from 'assets/images/implementation/Mockup@2x.png';

const VideoPlayer = () => {
   return (
      <Container>
         <VideoContainer>
            <img
               src={image}
               srcSet={`${image} 1x, ${imageX2} 2x`}
               alt="video background"
            />
         </VideoContainer>
      </Container>
   );
};

export default VideoPlayer;
