import React from 'react';

import SectionHeader from 'components/shared/SectionHeader';
import ListWithIcons from 'components/pages/benefits/ListWithIcons';

import illustration1 from 'assets/icons/Ilustracja2.svg';
import illustration2 from 'assets/icons/Ilustracja3.svg';
import illustration3 from 'assets/icons/Ilustracja4.svg';

const AdvantagesSection = () => {
   const sectionText = [<strong>Zalety</strong>, ' systemu Proces Control'];

   const listItems = [
      {
         header: [
            <strong>Oszczędność czasu</strong>,
            ' dzięki szybkiej wymianie informacji',
         ],
         description:
            'Każdy wynik pacjenta masz w systemie w czasie rzeczywistym.',
         image: illustration1,
      },
      {
         header: [
            <strong>Mniejsze ryzyko popełnienia błędu</strong>,
            ' przez pracowników',
         ],
         description:
            'System wesprze Cię w podejmowaniu decyzji o diagnozie jednostki chorobowej.',
         image: illustration2,
      },
      {
         header: [
            <strong>Cyfryzacja</strong>,
            ' to duża wygoda dla pacjentów i pracowników',
         ],
         description:
            'Pożegnaj papierowe segregatory i zawieszki z danymi pacjentów - teraz wszystko będzie w jednym i bezpiecznym miejscu.',
         image: illustration3,
      },
   ];

   return (
      <>
         <SectionHeader sectionText={sectionText} />
         <ListWithIcons listItems={listItems} />
      </>
   );
};

export default AdvantagesSection;
