import React from 'react';

import ListWithImage from 'components/pages/benefits/ListWithImage';
import { TechnologyContainer } from './TechnologySection.styled';

import image from 'assets/images/benefits/photo14.png';
import imageX2 from 'assets/images/benefits/photo14@2x.png';
import szablon from 'assets/icons/benefits/sample2.svg';

import { colors } from 'styles/variables';

const TechnologySection = () => {
   const listHeader = [
      'Wspieraj prace personelu ',
      <strong>dzięki nowej technologii</strong>,
   ];

   const listItems = [
      {
         headerText: [
            'Diagnozowanie wspierane przez ',
            <strong>Sztuczną inteligencję</strong>,
         ],
         description:
            'Dzięki wbudowanej bazie oraz wprowadzonym danym diagnostycznym, system odrzuca część możliwości występujących u pacjenta chorób.',
         icon: szablon,
      },
      {
         headerText: [
            <strong>Wspieranie decyzji lekarzy</strong>,
            ' przy pomocy bogatej bazie medycznej w systemie',
         ],
         description:
            'System nie decyduje i nie jest autonomiczny, on po prostu minimalizuje rzeczy, na które Twoi lekarze marnują czas.',
         icon: szablon,
      },
      {
         headerText: [
            'Połączenie wiedzy medycznej z ',
            <strong>inteligentną technologią</strong>,
         ],
         description:
            'Dzięki wbudowanej bazie jednostek chorobowych i technologii - 15 minutowa wizyta będzie w końcu rzeczywistością, a nie mrzonką.',
         icon: szablon,
      },
   ];

   return (
      <TechnologyContainer>
         <ListWithImage
            image={image}
            imageX2={imageX2}
            headerText={listHeader}
            listItems={listItems}
            reverseRow={true}
            bgColor={colors.lightViolet}
         />
      </TechnologyContainer>
   );
};

export default TechnologySection;
